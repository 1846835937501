<template>
  <div class="container">
    <van-tabs
      sticky
      swipeable
      animated
      class="tabs"
      background="var(--app-main-bg-color)"
      color="var(--app-active-color)"
      title-active-color="#fff"
      title-inactive-color="var(--app-tag-text-color)"
      v-model:active="active"
    >
      <van-tab class="tab" title="预约列表"
        ><div class="main-box">
          <div class="no-list" v-if="!list.length">暂无数据</div>
          <div class="list-card" v-if="list.length">
            <div class="title">火爆上线中</div>
            <div class="list">
              <div class="item" v-for="(item, index) in list" :key="index" @click="router.push({ name: 'GameReservationDetail', query: { id: item.id } })">
                <van-image class="logo" :src="item.logo" />
                <div class="desc">
                  <div class="name">{{ item.name }}</div>
                  <div class="tags">
                    <van-tag size="medium" v-for="(tag, tagIndex) in item.tags" :key="tagIndex">{{ tag }}</van-tag>
                  </div>
                </div>
                <div class="action">
                  <van-button block size="small" round color="var(--app-button-red-bg-color)" :class="{ disable: item.is_end }">{{
                    item.is_end ? '查看详情' : '查看详情'
                  }}</van-button>
                </div>
              </div>
            </div>
          </div>
        </div></van-tab
      >
      <van-tab title="我的预约">
        <div class="main-box">
          <div class="no-list" v-if="!playerList.length">暂无数据</div>
          <div class="list-card" v-if="playerList.length">
            <div class="title">火爆上线中</div>
            <div class="list">
              <div class="item" v-for="(item, index) in playerList" :key="index">
                <van-image class="logo" :src="item.logo" />
                <div class="desc" @click="router.push({ name: 'GameReservationDetail', query: { id: item.id } })">
                  <div class="name">{{ item.name }}</div>
                  <div class="tags">
                    <van-tag size="medium" v-for="(tag, tagIndex) in item.tags" :key="tagIndex">{{ tag }}</van-tag>
                  </div>
                </div>
                <div class="action">
                  <van-button block size="small" round color="var(--app-button-bg-color)" @click="showReservationInfo(item)">{{
                    item.activation ? '查看激活码' : '预约成功'
                  }}</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-dialog v-model:show="showReservationDialog" theme="round-button">
      <div class="van-dialog__message" v-if="showReservationData.activation">
        您已成功预约游戏《{{ showReservationData.name }}》，激活代码为：
        <h3 style="color: var(--app-active-color)">{{ showReservationData.activation }}</h3>
      </div>
      <div class="van-dialog__message" v-else>
        您已成功预约游戏《{{ showReservationData.name }}》，游戏开测前将通过微信消息通知您，请持续关注公众号，避免错过消息。
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'GameReservation',
}
</script>
<script setup>
import { Button, Tag, Image, Tab, Tabs, Dialog } from 'vant'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { gameReservationList } from '@/api/gameReservation'

const VanButton = Button
const VanTag = Tag
const VanImage = Image
const VanTab = Tab
const VanTabs = Tabs
const VanDialog = Dialog.Component

const store = useStore()
const router = useRouter()

const active = ref(0)
const list = ref([])
const playerList = ref([])

onMounted(() => {
  initData()
})

const initData = () => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
  gameReservationList().then(res => {
    list.value = res.data.list
    res.data.list.forEach(item => {
      if (item.player_reservation_id) {
        playerList.value.push(item)
      }
    })
  })
}

const showReservationDialog = ref(false)
const showReservationData = ref()
const showReservationInfo = data => {
  showReservationDialog.value = true
  showReservationData.value = data
}
</script>

<style lang="scss" scoped>
.container {
  .tabs {
    ::v-deep .van-tabs__wrap {
      border-radius: 0 0 10px 10px;
    }
    ::v-deep .van-tabs__line {
      width: 90px;
      height: 4px;
      border-radius: 3px 3px 0 0;
    }
  }
  .main-box {
    padding: 15px 12px;
    min-height: 85vh;
    .no-list {
      color: #969799;
      font-size: 14px;
      line-height: 20px;
      height: calc(100vh - 80px - constant(safe-area-inset-bottom));
      height: calc(100vh - 80px - env(safe-area-inset-bottom));
      text-align: center;
    }
    .list-card {
      background: var(--app-foot-color);
      box-sizing: border-box;
      border-radius: 15px;
      padding: 12px;

      .title {
        box-sizing: border-box;
        color: var(--app-main-title-color);
        font-size: 14px;
        font-weight: 400;
      }

      .item {
        &:last-child {
          border-bottom: none;
        }
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--app-main-bg-color);
        .logo {
          flex-shrink: 0;
          width: 50px;
          height: 50px;
          border-radius: 15px;
          overflow: hidden;
          margin-right: 10px;
        }
        .desc {
          width: 100%;
          .name {
            font-size: 12px;
            color: var(--app-text-color);
            padding: 5px 0;
            font-weight: 500;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;

            .van-tag {
              &:last-child {
                margin-right: 0;
              }
              font-size: 10px;
              // font-weight: 500;
              margin-right: 5px;
              margin-bottom: 5px;
              flex-shrink: 0;
              color: var(--app-tag-text-color);
            }
            .van-tag--default {
              --van-tag-default-color: var(--app-tag-bg-color);
            }
          }
        }
        .action {
          flex-shrink: 0;
          line-height: 100%;
          width: 80px;
          font-weight: 500;
          .disable {
            color: var(--app-menu-color) !important;
          }
        }
      }
    }
  }
}
</style>

